.page-content {
  align-self: flex-start;
  /* Aligns content to the top of the flex container */
  width: 100%;
  /* Takes the full width of the container */
}

.create-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.status-message.success {
  background-color: #DFF2BF;
  color: green;
}

.status-message.warning {
  background-color: #FEEFB3;
  color: #9F6000;
}

.status-message.error {
  background-color: #FFD2D2;
  color: red;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}