Add this CSS to your existing styles or create a new CSS file

/* Apply border and border-collapse for table */
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  /* Apply border to table header cells */
  /* th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  } */
  
  /* Apply border to table body cells */
  /* td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  } */
  
  /* Add alternate row background color for better visibility */
  /* tr:nth-child(even) {
    background-color: #f2f2f2;
  } */

  /* Add global styles here */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
  }
  
  /* Header styles */
  header {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
  }
  
  .navigation {
    display: flex;
    align-items: center;
  }
  
  .navigation li {
    list-style: none;
    margin-right: 1rem;
  }
  
  .navigation li:last-child {
    margin-right: 0;
  }
  
  .navigation a {
    text-decoration: none;
    color: #555555;
    transition: color 0.3s ease;
  }
  
  .navigation a:hover {
    color: #222222;
  }
  
  /* Main container styles */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  th,
  td {
    padding: 1rem;
    text-align: left;
  }
  
  th {
    font-weight: bold;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Button styles */
  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Add more specific styles as needed for your tables and other elements */
  
  /* Footer styles */
  footer {
    text-align: center;
    padding: 1rem;
    background-color: #333333;
    color: #ffffff;
  }
  
  