/* PlacesTable.css */
.red-button {
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
}

.blue-button {
  color: white;
  background-color: blue;
  border: none;
  cursor: pointer;
}

.grey-button {
  color: white;
  background-color: grey;
  border: none;
  cursor: pointer;
}

.remove-button {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  margin: 0;
  pointer-events: stroke;
}

.remove-button {
  color: red;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0; /* Optional: If you want to remove padding, adjust as needed */
}

.remove-button:hover {
  background-color: transparent;
}

/* Update PlacesTable.css */

.places-table {
  border-spacing: 0 10px; /* Add 10px spacing between rows */
}

/* Update PlacesTable.css */

.places-table td div {
  margin-bottom: 10px; /* Add 10px spacing between rows */
}

.no-wrap {
  white-space: nowrap;
}
